<form>
    <h6>Create an account</h6>
    <div class="form-group">
        <input type="text" class="form-control" placeholder="Username" required="required">
    </div>
    <div class="form-group">
        <input type="text" class="form-control" placeholder="email" required="required">
    </div>
    <div class="form-group">
        <input type="password" class="form-control" placeholder="Password" required="required">
    </div>
    <div class="form-group">
        <input type="password" class="form-control" placeholder="Confirm Password" required="required">
    </div>
    <div class="form-group small clearfix">
        <label class="checkbox-inline float-left"><input type="checkbox"> By checking box you agree to </label>
        <a href="#" class="forgot-link">Terms and Conditions</a>
    </div>
    <div class="form-group">
        <input type="submit" class="btn btn-primary btn-block btn-lg" value="Submit">
    </div>
    <div class="form-footer">Already have an account <a routerLink="/signin">Sign in</a></div>
</form>