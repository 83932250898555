<header class="header-main">
    <nav class="navbar navbar-expand-md fixed-top">
        <div class="container">
            <a routerLink="/" class="navbar-brand text-white">
                <img src="assets/HMH_v01.png" height="25" alt="Heal My Hero Logo"> 
            </a>
            <div class="collapse navbar-collapse right-nav" id="navbarCollapse">
                <div class="menu-item">
                    <a href="#" class="nav-item nav-link test-color">Stories</a>
                </div>
                <div class="dropdown menu-item" *ngIf="signedIn">
                    <a href="#" class="nav-link dropdown-toggle test-color" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Hello User</a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a href="#" class="dropdown-item">Dashboard</a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item">Profile</a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item">Logout</a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</header>