<div class="story-block">
    <div class="card">
        <img src="../assets/HMH_SAMPLE.jpg" class="card-img-top story-img" alt="sample HMH">
        <div class="card-body">
            <h5 class="card-title">Title</h5>
            <p class="card-text">Summary</p>
            <i class="fa fa-heart"></i>
        </div>
    </div>
    <div class="card">
        <img src="../assets/HMH_SAMPLE.jpg" class="card-img-top story-img" alt="sample HMH">
        <div class="card-body">
            <h5 class="card-title">Title</h5>
            <p class="card-text">Summary</p>
            <i class="fa fa-heart"></i>
        </div>
    </div>
    <div class="card">
        <img src="../assets/HMH_SAMPLE.jpg" class="card-img-top story-img" alt="sample HMH">
        <div class="card-body">
            <h5 class="card-title">Title</h5>
            <p class="card-text">Summary</p>
            <i class="fa fa-heart"></i>
        </div>
    </div>
    <div class="card">
        <img src="../assets/HMH_SAMPLE.jpg" class="card-img-top story-img" alt="sample HMH">
        <div class="card-body">
            <h5 class="card-title">Title</h5>
            <p class="card-text">Summary</p>
            <i class="fa fa-heart"></i>
        </div>
    </div>
    <div class="card">
        <img src="../assets/HMH_SAMPLE.jpg" class="card-img-top story-img" alt="sample HMH">
        <div class="card-body">
            <h5 class="card-title">Title</h5>
            <p class="card-text">Summary</p>
            <i class="fa fa-heart"></i>
        </div>
    </div>
    <div class="card">
        <img src="../assets/HMH_SAMPLE.jpg" class="card-img-top story-img" alt="sample HMH">
        <div class="card-body">
            <h5 class="card-title">Title</h5>
            <p class="card-text">Summary</p>
            <i class="fa fa-heart"></i>
        </div>
    </div>
</div>