import { Component, OnInit } from '@angular/core';
import { Story } from '../story/story.model';
@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.css']
})
export class StoryComponent implements OnInit {
  stories: Story[] = [
    new Story('Test 1', 'This is a test', 'Test', 1, '', ''),
    new Story('Test 2', 'This is a test', 'Test', 2, '', ''),
    new Story('Test 3', 'This is a test', 'Test', 3, '', ''),
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
