<form>
    <h6>Please Sign in</h6>
    <div class="form-group">
        <i class="fa fa-user"></i>
        <input type="text" class="form-control" placeholder="Username" required="required">
    </div>
    <div class="form-group">
        <i class="fa fa-lock"></i>
        <input type="password" class="form-control" placeholder="Password" required="required">
    </div>
    <div class="form-group small clearfix">
        <label class="checkbox-inline float-left"><input type="checkbox"> Remember me</label>
        <a href="#" class="forgot-link float-right">Forgot Password?</a>
    </div>
    <div class="form-group">
        <input type="submit" class="btn btn-secondary btn-block btn-lg" value="Sign in">
    </div>
    <div class="form-footer">Don't have an account? <a routerLink="/signup">Sign up</a></div>
</form>