<footer class="footer-main">
    <nav class="navbar fixed-bottom">
        <div class="container">
            <p class="navbar-brand">HEAL MY HERO &copy; 2020 </p>
            <div class="footer-links">
                <a href="#" class="test-color">Term of Service</a>
                <a href="#" class="test-color">Contact Us</a>
            </div>
            <div class="footer-right">
                <a href="#"><i class="fa fa-facebook"></i></a>
                <a href="#"><i class="fa fa-twitter"></i></a>
                <a href="#"><i class="fa fa-linkedin"></i></a>
                <a href="#"><i class="fa fa-instagram"></i></a>
            </div>
        </div>
    </nav>
</footer>