export class Story {
    public title: string;
    public content: string;
    public summary: string;
    public hearts: number;
    public report: string;
    public imagePath: string;

    constructor(title: string, cont: string, summ: string, hearts: number, report: string, imageP: string) {
        this.title = title;
        this.content = cont;
        this.summary = summ;
        this.hearts = hearts;
        this.report = report;
        this.imagePath = imageP;
    }
}
